import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, digits, length } from '@validations'
import vSelect from "vue-select";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import gql from "graphql-tag";
import { avatarText } from "@core/utils/filter";
import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/pt-br';
import { Form } from 'vform';
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from './pt.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup, BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody, VBPopover,
    BPagination,
} from "bootstrap-vue";

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'


const ADD = gql`
mutation addCity($name: String!, $uf: uuid!) {
    insert_cities(objects: {name: $name, state_uuid: $uf}) {
      affected_rows
    }
  }
`;

const UPDATE = gql`
mutation updateCity($uuid_city : uuid!, $name: String!, $uf: uuid!) {
    update_cities(_set: {name: $name, state_uuid: $uf}, where: {uuid_city: {_eq: $uuid_city}}) {
      affected_rows
    }
  }
`;

const DELETE = gql`
mutation deleteCity($uuid_city : uuid!) {
    delete_cities(where: {uuid_city: {_eq: $uuid_city}}) {
      affected_rows
    }
  }
`;

const GET = gql`
query getCities {
    cities {
      uuid_city
      name
      state {
        name
        uf
      }
    }
  }  
`;

const GET_STATES = gql `
query getStates{
    states {
      uuid_state
      name
      uf
  }
}
`;

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
        quillEditor,
        flatPickr,
        BCardText,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        vSelect,
    },

    directives: {
        'b-popover': VBPopover,
    },

    data() {
        return {
            titleForm: 'Adicionar cidade',
            snowOption: null,
            required,
            digits,
            length,
            editMode: false,
            optionsDate:
            {
                locale: Portuguese,
                enableTime: false,
                dateFormat: 'm-d-Y',
                time_24hr: false,
                minDate: 'today'
            }
            ,
            expires_at: null,
            form: new Form({
                uuid_city: null,
                name: null,
                uf: null,
            }),
            options: {
                date: {
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                },
            },
            perPage: 5,
            totalRows: 1,
            currentPage: 1,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'state.uf', label: 'UF', sortable: true },
                { key: 'actions', label: 'Ações', sortable: false },
            ],
            items: [],
            states : [],
        }
    },
    
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
    },

    created() {
        moment.locale('pt-br')
       localize('pt_BR', Portuguese);
        this.getRecords();

    },
    methods: {

        newCities() {
            this.titleForm = 'Adicionar Cidade',
                this.editMode = false;
            this.form.reset();
            this.$refs['citiesModal'].show()

        },

        newCity() {
            let {
                name,
            } = this.$data.form;
            let uf = this.form.uf.uuid_state

            this.$apollo.mutate({
                mutation: ADD,
                variables: {
                    name,
                    uf,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['citiesModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Cidade adicionada!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Cdiade não adicionada! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });
        },
        editCity(c) {
            this.titleForm = 'Editando cidade ';
            this.editMode = true;
            this.$data.form.fill(c);
            this.$data.form.uf = c.state;
            this.$refs['citiesModal'].show()

        },
        updateCity() {
            let {
                uuid_city,
                name,
            } = this.$data.form;
            let uf = this.form.uf.uuid_state

            this.$apollo.mutate({
                mutation: UPDATE,
                variables: {
                    name,
                    uf,
                    uuid_city: uuid_city,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['citiesModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Cidade atualizada!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Erro ao atualizar Cidade! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });
        },

        deleteCity(uuid) {

            this.$bvModal
                .msgBoxConfirm('Deseja realmente excluir esta Cidade?.', {
                    title: 'ATENÇÃO!',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.$apollo.mutate({
                            mutation: DELETE,
                            variables: {
                                uuid_city: uuid
                            }
                        }).then(() => {
                            this.getRecords();
                            this.$refs['citiesModal'].hide()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Sucesso',
                                    icon: 'CheckIcon',
                                    text: 'Cidade deletada!',
                                    variant: 'success',
                                },
                            },
                                {
                                    position: 'bottom-right',
                                })
                        }).catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erro',
                                    icon: 'XIcon',
                                    text: 'Erro ao excluir a Cidade! ' + error,
                                    variant: 'danger',
                                },
                            },
                                {
                                    position: 'bottom-right',
                                })
                        });
                    }
                })


        },

        formatFullDate(data) {
            return moment(data).format("DD/MM/YY [às] HH:mm:ss")
        },

        formatDatetoUpdate(data) {
            return moment(data).format("MM-DD/YY")
        },

        getRecords() {
            this.$apollo.query({
                query: GET,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.items = data['data']['cities'];
                this.totalRows = this.items.length
            });

            this.$apollo.query({
                query: GET_STATES,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.states = data['data']['states'];
            });
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },

    setup() {
        return {
            avatarText,
        }
    }

}